import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'contentContainer',
    'contentHtml',
    'contentLabel',
    'contentLink',
    'contentBadges'
  ]
  static classes = ['inactive']
  static values = {
    contentIdx: { type: String, default: '0' },
    contentUrl: { type: String, default: '' },
    contentTitle: { type: String, default: '' },
    contentBadges: { type: Array, default: [] }
  }

  scrollToContentStart() {
    const container = document.getElementsByClassName("update-content")[0];
    window.scrollTo({ top: container.offsetTop, behavior: "smooth" });
  }

  updateSelection(event) {
    const newContentIdx = event.target.getAttribute('value');
    this.contentIdxValue = newContentIdx;
    this.scrollToContentStart();
  }

  contentIdxValueChanged(current, prev) {
    if (prev) this.contentHtmlTarget.classList.add('appear');
    this.contentContainerTargets.forEach((contentContainer) => {
      if (contentContainer.dataset.contentIdx === this.contentIdxValue) {
        this.contentHtmlTarget.innerHTML = contentContainer.dataset.contentHtml;
        this.setupContent(contentContainer);
      }
    });

    setTimeout(() => {
      this.contentHtmlTarget.classList.remove('appear');
    }, 300);
  }

  contentTypeToLabel(type) {
    if (type === 'fe') return 'Fe de errores';
    if (type === 'aclaracíon') return 'Aclaracíon';

    return null;
  }

  setupContent(contentContainer) {
    this.contentUrlValue = contentContainer.dataset.contentUrl;
    if (this.contentIdxValue === '0') {
      this.contentBadgesValue = ['Texto vigente'];
    } else {
      this.contentBadgesValue = [];
    }

    const contentTypeLabel = this.contentTypeToLabel(contentContainer.dataset.contentType);
    if (contentTypeLabel) {
      this.contentBadgesValue = [...this.contentBadgesValue, contentTypeLabel];
    }

    const totalReformCount = contentContainer.dataset.contentTotal;
    const thisReformCount = totalReformCount - Number(this.contentIdxValue);
    const contentNumberLabel = `Reforma ${thisReformCount} de ${totalReformCount}`;
    this.contentBadgesValue = [...this.contentBadgesValue, contentNumberLabel];

    this.contentTitleValue = contentContainer.dataset.contentTimestamp;
  }

  contentBadgesValueChanged() {
    const badges = this.contentBadgesValue.reduce((summ, badge) => {
      summ += "<div class='badge'>" + badge + "</div>";
      return summ;
    }, "");

    if (badges.length === 0) {
      this.contentBadgesTarget.classList.add('hidden');
    } else {
      this.contentBadgesTarget.classList.remove('hidden');
    }

    this.contentBadgesTarget.innerHTML = badges;
  }

  contentUrlValueChanged() {
    const anchorLink = this.contentLinkTarget.getElementsByTagName('a')[0];
    anchorLink.href = this.contentUrlValue;
  }

  contentTitleValueChanged() {
    const titleElement = this.contentLabelTarget;
    titleElement.innerHTML = this.contentTitleValue;
  }

  contentContainerTargetConnected(element) {
    if (element.dataset.contentIdx !== this.contentIdxValue) {
      element.classList.add(this.inactiveClass)
    }
  }
  
}